import {Switch,Route,useLocation} from 'react-router-dom';

//Dashboard2
import Dashboard2 from '../views/backend/Dashboards/Dashboard2'

const Layout2Route = () =>{
    let location = useLocation();
    return(
        <Switch  location={location}>
          <Route path="/" exact component={Dashboard2} />
        </Switch>
    );
};

export default Layout2Route;
