import React from 'react';
import './LoaderSimulator.css';

import { connect } from "react-redux";
import { getIsCalculating } from '../../store/mode';

const LoaderSimulator = (props) => {
  return (
    <>
      {props.isCalculating && (
        <div className="loading-general-container">
          <div className="loading-steps">
            <div className="loading-steps__step">
              <span className="loading-steps__icon">
                <span className="material-icons" aria-hidden="true">
                  <i className="fas fa-users loading-steps__icon-content"></i>
                </span>
              </span>
              <span className="loading-steps__text" aria-hidden="true">
                Personalizando tu plan
              </span>
            </div>
            <div className="loading-steps__step">
              <span className="loading-steps__icon">
                <span className="material-icons" aria-hidden="true">
                  <i className="fas fa-calculator loading-steps__icon-content"></i>
                </span>
              </span>
              <span className="loading-steps__text">
                Calculando tus rendimientos
              </span>
            </div>
            <div className="loading-steps__step">
              <span className="loading-steps__icon">
                <span className="material-icons" aria-hidden="true">
                  <i className="fas fa-balance-scale loading-steps__icon-content"></i>
                </span>
              </span>
              <span className="loading-steps__text">
                Considerando la mejor opción
              </span>
            </div>
            <div className="loading-steps__step">
              <span className="loading-steps__icon">
                <span className="material-icons" aria-hidden="true">
                  <i className="fas fa-wallet loading-steps__icon-content"></i>
                </span>
              </span>
              <span className="loading-steps__text">
                Garantizando tu retiro digno
              </span>
            </div>
            <div className="loading-steps__step">
              <span className="loading-steps__icon">
                <span className="material-icons" aria-hidden="true">
                  <i className="far fa-money-bill-alt loading-steps__icon-content"></i>
                </span>
              </span>
              <span className="loading-steps__text">
                Asegurando tus metas financieras
              </span>
            </div>
            <div className="loading-steps__step">
              <span className="loading-steps__icon">
                <span className="material-icons" aria-hidden="true">
                  <i className="fas fa-chart-line loading-steps__icon-content"></i>
                </span>
              </span>
              <span className="loading-steps__text">
                Protegiendo tu patrimonio
              </span>
            </div>
            <div className="loading-steps__bar"></div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isCalculating: getIsCalculating(state)
  };
};

export default connect(mapStateToProps, null)(LoaderSimulator);