import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Col, Container, Row, Dropdown, Button, Modal } from 'react-bootstrap';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Card from '../../../components/Card';
import useWindowSize from '../../../hooks/useWindowSize';
import OtherOptionModal from '../../../components/OtherOptionModal';
import { getSimulationData, buildTableRegisters, sendDocumentService, currencyFormat } from '../../../helpers';

import { isCalculatingAction, getIsCalculating, isGeneratingDocumentAction, isAboutToSendEmailAction, isAllowedToSendEmailAction } from '../../../store/mode';

const Dashboard2 = (props) => {
  const [formData, setFormData] = useState({});
  const [aportacionesAcumuladasaEdad, setAportacionesAcumuladasaEdad] = useState(null);
  const [saldoProyectadoaEdad, setSaldoProyectadoaEdad] = useState(null);
  const [porcentajeBono, setPorcentajeBono] = useState(null);
  const [bonoAcreditado, setBonoAcreditado] = useState(null);
  const [isCalculationAllowed, setIsCalculationAllowed] = useState(false);
  const [simulationData, setSimulationData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableExclusiveData, setTableExclusiveData] = useState(null);
  const [isProcessQueued, setIsProcessQueued] = useState(false);
  const [periodicityLabel, setPeriodicityLabel] = useState(null);
  const [incrementoConInflacionLabel, setIncrementoConInflacionLabel] = useState(null);
  const [savingsQtyLabel, setSavingsQtyLabel] = useState(null);
  const [savingsTimeLabel, setSavingsTimeLabel] = useState(null);
  const [isrAplicableLabel, setIsrAplicableLabel] = useState(null);
  const [planLabel, setPlanLabel] = useState(null);
  const [savingsTimeOtherOption, setSavingsTimeOtherOption] = useState(false);
  const [savingsQtyOtherOption, setSavingsQtyOtherOption] = useState(false);
  const [isProjectionVisible, setIsProjectionVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isInformationMissing, setIsInformationMissing] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [chart1, setChart1] = useState({
    options: {
      chart: {
        id: 'layout-2-chart-01',
        zoom: {
          enabled: false
        }
      },
      responsive: [{
        breakpoint: undefined
      }],
      colors: ['#33A74D', '#EDCD38', '#37D5F2'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Saldo proyectado anual',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          formatter: (val, index) => {
            return Math.abs(val) > 999999 ? Math.sign(val) * ((Math.abs(val) / 1000000).toFixed(1)) + ' M' : Math.abs(val) > 999 ? Math.sign(val) * ((Math.abs(val) / 1000).toFixed(1)) + ' k' : Math.sign(val) * Math.abs(val)
          }
        }
      }
    },
    series: [{
      name: "Saldo del Fondo",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      name: "Aportación Acumulada",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      name: "Beneficio Deducibilidad",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }]
  });

  const { isMobile } = useWindowSize();

  const toggleTable = () => {
    props.isAllowedToSendEmailAction(!isTableVisible)
    setIsTableVisible(prevState => !prevState);
    setTimeout(() => {
      document.getElementById('layout-2-chart-01').scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleInflacionFormChange = e => {
    const selected = JSON.parse(e);
    setFormData(prevState => ({ ...prevState, incremento_con_inflacion: selected.value }));
    setIncrementoConInflacionLabel(selected.label);
  };

  const handleSavingFormChange = e => {
    const selected = JSON.parse(e);
    if (selected.value === 'otro') {
      setSavingsQtyOtherOption(true);
      return;
    }
    setFormData(prevState => ({ ...prevState, savingsQty: selected.value }));
    setSavingsQtyLabel(selected.label);
  };

  const handlePeriodicityFormChange = async e => {
    const selected = JSON.parse(e);
    setFormData(prevState => ({ ...prevState, periodicidad: selected.value }));
    setPeriodicityLabel(selected.label);
  };

  const handlePlanFormChange = e => {
    const selected = JSON.parse(e);
    setFormData(prevState => ({ ...prevState, plan: selected.value }));
    setPlanLabel(selected.label);
  };

  const handleIsrFormChange = e => {
    const selected = JSON.parse(e);
    setFormData(prevState => ({ ...prevState, isr: selected.value }));
    setIsrAplicableLabel(selected.label);
  };

  const handleTimeFormChange = e => {
    const selected = JSON.parse(e);
    if (selected.value === 'otro') {
      setSavingsTimeOtherOption(true);
      return;
    }
    setFormData(prevState => ({ ...prevState, savingsTime: selected.value }));
    setSavingsTimeLabel(selected.label);
  };

  const handleModalVisibility = () => {
    setIsInformationMissing(prevState => !prevState);
  };

  const handleOtherOptionModalVisibility = () => {
    setSavingsTimeOtherOption(false);
    setSavingsQtyOtherOption(false);
  };

  const runSimulation = async () => {
    let chartDataResponse;
    let tableDataResponse;
    if (!formData.name || !formData.age || !formData.savingsQty || !formData.savingsTime) {
      handleModalVisibility();
      return;
    }
    setIsCalculationAllowed(false);
    try {
      props.isCalculatingAction(true)
      chartDataResponse = await getSimulationData(formData);
      localStorage.setItem('simulation', JSON.stringify(chartDataResponse))
      setSimulationData(chartDataResponse.data);
      setPorcentajeBono(Number(chartDataResponse.data.porcentaje_bono) * 100);
      setBonoAcreditado(Number(chartDataResponse.data.porcentaje_bono) * Number(chartDataResponse.data.aportacion_anual_acumulada_list[0]))
      setAportacionesAcumuladasaEdad(chartDataResponse.data.aportaciones_acumuladas_a_edad)
      setSaldoProyectadoaEdad(chartDataResponse.data.saldo_proyectado_a_edad)
      tableDataResponse = await buildTableRegisters(chartDataResponse.data)
      setTableData(chartDataResponse.data);
      setTableExclusiveData(tableDataResponse);
    } catch (error) {
      props.isCalculatingAction(false)
    } finally {
      setIsProjectionVisible(true);
      props.isCalculatingAction(false)
    }
  };

  const generateDocumentName = (type) => {
    const name = formData.name.split(' ').join('_');
    const definitiveName = `${name}_${formData.age}_${formData.savingsQty}_${formData.savingsTime}.${type}`;
    return definitiveName;
  };

  const printDocument = () => {
    setIsProcessQueued(true);
    props.isGeneratingDocumentAction(true);
    setTimeout(() => {
      const input = document.getElementById('divToPrint');
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 10, 10, 200, 287);
          pdf.save(generateDocumentName('pdf'));
          props.isGeneratingDocumentAction(false);
          setIsProcessQueued(false);
        });
    }, 500);
  };

  const handleEmailModal = () => {
    setIsEmailModalVisible(prevState => !prevState)
  };

  const verifyEmailIsValid = () => {
    let pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    let str = formData.email;
    return str.match(pattern);
  };

  const sendDocumentToEmail = () => {
    setIsProcessQueued(true);
    const isEmailValid = verifyEmailIsValid();
    if (!isEmailValid) {
      setIsEmailValid(isEmailValid);
      setTimeout(() => {
        setIsEmailModalVisible(false);
      }, 2000);
      return;
    }
    props.isGeneratingDocumentAction(true);
    setIsTableVisible(true);
    setTimeout(() => {
      const input = document.getElementById('divToPrint');
      html2canvas(input)
      .then(async (canvas) => {
        await canvas.toBlob(async (blb) => {
          var reader = new FileReader()
          let data
          await reader.readAsDataURL(blb)
          reader.onload = (e) => {
            data = e.target.result
            data = data.split(",").pop();
            const fileName = generateDocumentName('png')
            sendDocumentService(data, formData.name, fileName, formData.email)
          }
        }, 'image/png');
        setIsProcessQueued(false);
        props.isGeneratingDocumentAction(false);
        setTimeout(() => {
          setIsEmailModalVisible(false);
        }, 500);
      });
    }, 500);
  };

  const validateIfFormIsComplete = (formState) => {
    const {
      age,
      savingsQty,
      savingsTime,
      incremento_con_inflacion,
      isr,
      periodicidad,
      plan
    } = formState;
    if (
      !!(age &&
        savingsQty &&
        savingsTime &&
        incremento_con_inflacion &&
        (isr || plan === 93) &&
        periodicidad &&
        plan)
    ) {
      setIsCalculationAllowed(true);
    } else {
      setIsCalculationAllowed(false);
    }
  }

  useEffect(() => {
    validateIfFormIsComplete(formData)
  }, [formData]);

  useEffect(() => {
    if (tableData) {
      const chartRef = { ...chart1 }
      chartRef.options.xaxis.categories = tableData.edad_list.map(ageYear => ageYear % 5 === 0 ? ageYear : '');
      chartRef.series[0].data = tableData.saldo_del_fondo;
      chartRef.series[1].data = tableData.aportacion_anual_acumulada_list;
      chartRef.series[2].data = tableData.beneficio_deducibilidad_list;
      setChart1(chartRef)
    }
  }, [tableData]);

  return (
    <>
      <Modal show={isInformationMissing} onHide={handleModalVisibility} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">¡Falta Información!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Para poder calcular tu retiro ideal nos hace falta: &nbsp;<br /><br />
          {!formData.name && <p>- tu nombre para poder personalizar tu simulación<br /></p>}
          {!formData.age && <p>- la edad que tienes para calcular a qué edad recibirás tu ahorro<br /></p>}
          {!formData.savingsQty && <p>- la cantidad que ahorrarás<br /></p>}
          {!formData.savingsTime && <p>- el tiempo que ahorraras esta cantidad<br /></p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalVisibility}>Entendido</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isEmailModalVisible} onHide={handleEmailModal} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">{
            isEmailValid ?
              'Enviar reporte por correo' :
              'El correo es inválido'
          }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow-none m-0 border-0">
            <Card.Body className="p-0 ">
              <div className="p-3">
                <div to="#" className="mm-sub-card">
                  <div className="media align-items-center">
                    <div className="media-body ml-3">
                      <input className="form-control" type="email" placeholder="juan.perez@correo.com" name="email" onChange={handleFormChange} />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={sendDocumentToEmail}>Enviar</Button>
        </Modal.Footer>
      </Modal>
      <OtherOptionModal
        modalCase={savingsTimeOtherOption ? 'savingsTime' : 'savingsQty'}
        show={savingsTimeOtherOption || savingsQtyOtherOption}
        onCancel={handleOtherOptionModalVisibility}
        setFormData={setFormData}
        setSavingsTimeLabel={setSavingsTimeLabel}
        setSavingsQtyLabel={setSavingsQtyLabel}
      />
      <Container fluid className="container-md" id="divToPrint">
        <Row>
          <Col lg="12" className="mb-3">
            <div className="d-flex align-items-center justify-content-between welcome-content">
              <div className="navbar-breadcrumb">
                <h4 className="mb-1">Tabulador Optimax Plus</h4>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className="text-primary icon mm-icon-box-2 d-block mx-auto rounded">
                    <svg className="svg-icon" id="mm-user-1-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>¿Cuál es tu nombre?</label>
                    <input className="form-control" type="text" placeholder="Juan Pérez" name='name' onChange={handleFormChange} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className="text-danger icon mm-icon-box-2 d-block mx-auto rounded">
                    <svg className="svg-icon" id="mm-form-2-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>Edad</label>
                    <input className="form-control" type="number" min="18" max="65" maxLength="2" placeholder="31" name='age' onChange={handleFormChange} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className="text-success icon mm-icon-box-2 d-block mx-auto rounded">
                    <svg className="svg-icon" id="mm-extra-1-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>¿Cuánto quieres ahorrar?</label>
                    <div className="card-header-toolbar d-flex align-items-center">
                      <Dropdown onSelect={handleSavingFormChange}>
                        <Dropdown.Toggle as="span">{savingsQtyLabel ? savingsQtyLabel : 'Selecciona'}
                          <i className="ri-arrow-down-s-line ml-1"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" dropdown-menu-right">
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$2,000.00', value: 2000 })}>$2,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$3,000.00', value: 3000 })}>$3,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$4,000.00', value: 4000 })}>$4,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$5,000.00', value: 5000 })}>$5,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$6,000.00', value: 6000 })}>$6,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$7,000.00', value: 7000 })}>$7,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$8,000.00', value: 8000 })}>$8,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$9,000.00', value: 9000 })}>$9,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '$10,000.00', value: 10000 })}>$10,000.00</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Otra cantidad', value: 'otro' })}>Otra cantidad</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className=" icon mm-icon-box-2 d-block mx-auto text-warning rounded">
                    <svg className="svg-icon svg-warning mr-4" id="mm-cadacuando-1-16" width="50" height="52" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>¿Cada cuando?</label>
                    <div className="card-header-toolbar d-flex align-items-center">
                      <Dropdown onSelect={handlePeriodicityFormChange}>
                        <Dropdown.Toggle as="span">{periodicityLabel ? periodicityLabel : 'Selecciona'}
                          <i className="ri-arrow-down-s-line ml-1"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" dropdown-menu-right">
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Mensual', value: 'mensual' })}>Mensual</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Trimestral', value: 'trimestral' })}>Trimestral</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Semestral', value: 'semestral' })}>Semestral</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Anual', value: 'anual' })}>Anual</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className="icon mm-icon-box-2 d-block mx-auto text-purple rounded">
                    <svg className="svg-icon svg-purple" id="mm-tiempo-1-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>¿Cuánto tiempo?</label>
                    <div className="card-header-toolbar d-flex align-items-center">
                      <Dropdown onSelect={handleTimeFormChange}>
                        <Dropdown.Toggle as="span">{savingsTimeLabel ? savingsTimeLabel : 'Selecciona'}
                          <i className="ri-arrow-down-s-line ml-1"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" dropdown-menu-right">
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '10 años', value: 10 })}>10 años</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '15 años', value: 15 })}>15 años</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '20 años', value: 20 })}>20 años</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '25 años', value: 25 })}>25 años</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Otra cantidad', value: 'otro' })}>Otro plazo</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className="text-primary icon mm-icon-box-2 d-block mx-auto rounded">
                    <svg className="svg-icon" id="mm-tipoplan-1-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>Tipo de Plan</label>
                    <div className="card-header-toolbar d-flex align-items-center">
                      <Dropdown onSelect={handlePlanFormChange}>
                        <Dropdown.Toggle as="span">{planLabel ? planLabel : 'Selecciona'}
                          <i className="ri-arrow-down-s-line ml-1"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" dropdown-menu-right">
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Deducible Art. 151', value: 151 })}>Deducible Art. 151</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'CEA Art. 185', value: 185 })}>CEA Art. 185</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Plan de inversión Art. 93', value: 93 })}>Plan de inversión Art. 93</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" sm="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="top-block-one text-center">
                  <div className="text-cyan icon mm-icon-box-2 d-block mx-auto rounded">
                    <svg className="svg-icon svg-cyan" id="mm-exdtra-1-10" width="50" height="52" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                  </div>
                  <div className="mt-4">
                    <label>¿Incremento con inflación?</label>
                    <div className="card-header-toolbar d-flex align-items-center">
                      <Dropdown onSelect={handleInflacionFormChange}>
                        <Dropdown.Toggle as="span">{incrementoConInflacionLabel ? incrementoConInflacionLabel : 'Selecciona'}
                          <i className="ri-arrow-down-s-line ml-1"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" dropdown-menu-right">
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'Sí', value: 2 })}>Sí</Dropdown.Item>
                          <Dropdown.Item href="#" eventKey={JSON.stringify({ label: 'No', value: 1 })}>No</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" sm="6">
            {
              formData.plan !== 93 && (
                <Card className="card-block card-stretch card-height">
                  <Card.Body>
                    <div className="top-block-one text-center">
                      <div className=" icon mm-icon-box-2 d-block mx-auto text-yellow rounded">
                        <svg className="svg-icon svg-yellow" id="mm-uitt-2-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>
                      </div>
                      <div className="mt-4">
                        <label>ISR aplicable</label>
                        <div className="card-header-toolbar d-flex align-items-center">
                          <Dropdown onSelect={handleIsrFormChange}>
                            <Dropdown.Toggle as="span">{isrAplicableLabel ? isrAplicableLabel : 'Selecciona'}
                              <i className="ri-arrow-down-s-line ml-1"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=" dropdown-menu-right">
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '10%', value: 0.10 })}>10%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '15%', value: 0.15 })}>15%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '20%', value: 0.20 })}>20%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '25%', value: 0.25 })}>25%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '30%', value: 0.30 })}>30%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '32%', value: 0.32 })}>32%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '34%', value: 0.34 })}>34%</Dropdown.Item>
                              <Dropdown.Item href="#" eventKey={JSON.stringify({ label: '35%', value: 0.35 })}>35%</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )
            }
          </Col>
        </Row>
        {
          !isProcessQueued && (
            <div style={{ width: '100%' }} className="d-flex align-items-center justify-content-center">
              <button style={!isCalculationAllowed ? { cursor: 'not-allowed', marginBottom: '2rem' } : { marginBottom: '2rem' }} onClick={runSimulation} className="btn btn-primary rounded-small mt-4" disabled={!isCalculationAllowed} >Calcular</button>
            </div>
          )
        }
        {
          isProjectionVisible &&
          <>
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch card-height">
                  <Card.Header className="flex-wrap">
                    <Card.Header.Title>
                      <h4 className="card-title mb-1">Proyección Allianz</h4>
                      <span className="mb-0">{formData.age} - {Number(formData.age) + Number(formData.savingsTime)} años</span>
                    </Card.Header.Title>
                  </Card.Header>
                  <Card.Body>
                    <Chart options={chart1.options} series={chart1.series} type="area" height="400" />
                    {
                      !isProcessQueued && (
                        <>
                          <div id="layout-2-chart-01"></div>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div>
                              <p onClick={toggleTable} className="btn btn-primary rounded-small mt-4 mr-3">
                                {
                                  !isTableVisible ?
                                    'Ver desglose' :
                                    'Ocultar desglose'
                                }
                              </p>
                              {
                                isTableVisible && !isMobile && (<p onClick={printDocument} className="btn btn-secondary-button rounded-small mt-4">Descargar</p>)
                              }
                            </div>
                            <div>
                              {
                                isTableVisible && !isMobile && (<p onClick={handleEmailModal} className="btn btn-secondary rounded-small mt-4">Enviar por correo</p>)
                              }
                            </div>
                          </div>
                        </>
                      )
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {
              isTableVisible && (
                <Row>
                  <Col lg="12">
                    <Card className="card-block card-stretch card-height">
                      <Card.Header>
                        <Card.Header.Title>
                          <h4 className="card-title">Ahorro proyectado</h4>
                        </Card.Header.Title>
                      </Card.Header>
                      <Card.Body>
                        <div className="table-responsive">
                          <table className="table mb-0 table-bordered data-tables">
                            <thead className="light" style={{ fontSize: '12px !important' }}>
                              <tr>
                                <th scope="col">Año</th>
                                <th scope="col">Edad</th>
                                <th scope="col">Aportación Anual</th>
                                <th scope="col">Aportación Acumulada</th>
                                <th scope="col">Saldo del Fondo</th>
                                <th scope="col">Saldo Disponible</th>
                                <th scope="col">Saldo Disponible <p className='table-data-secondary-text'>(después de impuestos)</p></th>
                                {
                                  (formData.plan === 185 ||
                                    formData.plan === 151
                                  ) && (
                                    <th scope="col">Beneficio Deducibilidad Acumulado</th>
                                  )
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                tableExclusiveData && tableExclusiveData.map((register, i) => (
                                  <tr key={i}>
                                    <td>{register.años_list}</td>
                                    <td>{register.edad_list}</td>
                                    <td>{currencyFormat(Number(register.aportacion_anual_list).toFixed(0))}</td>
                                    <td>{currencyFormat(Number(register.aportacion_anual_acumulada_list).toFixed(0))}</td>
                                    <td>{currencyFormat(Number(register.saldo_del_fondo).toFixed(0))}</td>
                                    <td>{currencyFormat(Number(register.saldo_disponible).toFixed(0))}</td>
                                    <td>{currencyFormat(Number(register.saldo_disponible_neto_de_retención_de_impuestos_de_allianz).toFixed(0))}</td>
                                    {
                                      (formData.plan === 185 ||
                                        formData.plan === 151
                                      ) && (
                                        <td>{currencyFormat(Number(register.beneficio_deducibilidad_list).toFixed(0))}</td>
                                      )
                                    }
                                  </tr>
                                ))
                              }
                            </tbody>
                            <tfoot style={{ borderStyle: 'solid' }}>
                              <tr style={{ borderStyle: 'solid' }}>
                                <td colSpan={4}>
                                  <span style={{ fontWeight: 'bold' }}>Aportaciones acumuladas a edad {Number(formData.age) + Number(formData.savingsTime) >= 65 ? Number(formData.age) + Number(formData.savingsTime) : 65}:&nbsp;&nbsp;</span>
                                  <span style={{ fontWeight: 'bold' }}>{currencyFormat(Number(aportacionesAcumuladasaEdad).toFixed(0))}</span>
                                </td>
                                <td colSpan={4}>
                                  <span style={{ fontWeight: 'bold' }}>Tasa anual proyectada:&nbsp;&nbsp;</span>
                                  <span style={{ fontWeight: 'bold' }}>12%</span>
                                </td>
                              </tr>
                              <tr style={{ borderStyle: 'solid' }}>
                                <td colSpan={4}>
                                  <span style={{ fontWeight: 'bold' }}>Saldo proyectado a edad {Number(formData.age) + Number(formData.savingsTime) >= 65 ? Number(formData.age) + Number(formData.savingsTime) : 65}:&nbsp;&nbsp;</span>
                                  <span style={{ fontWeight: 'bold' }}>{
                                    Number(formData.age) + Number(formData.savingsTime) >= 65 ?
                                    currencyFormat(Number(simulationData.saldo_del_fondo[simulationData.saldo_del_fondo.length - 1]).toFixed(0)) :
                                    currencyFormat(Number(saldoProyectadoaEdad).toFixed(0))
                                    }</span>
                                </td>
                                {
                                  (formData.plan === 185 ||
                                    formData.plan === 151
                                  ) && (
                                    <td colSpan={4}>
                                      <span style={{ fontWeight: 'bold' }}>Valor acumulado del beneficio fiscal a edad {Number(formData.age) + Number(formData.savingsTime)}:&nbsp;&nbsp;</span>
                                      <span style={{ fontWeight: 'bold' }}>{currencyFormat(Number(simulationData.beneficio_deducibilidad_list[simulationData.beneficio_deducibilidad_list.length - 1]).toFixed(0))}</span>
                                    </td>
                                  )
                                }
                              </tr>
                              {
                                porcentajeBono > 0  && (
                                  <tr style={{ borderStyle: 'solid' }}>
                                    <td colSpan={4}>
                                      <span style={{ fontWeight: 'bold' }}>Bono Acreditado:&nbsp;&nbsp;</span>
                                      <span style={{ fontWeight: 'bold' }}>{ currencyFormat(bonoAcreditado.toFixed(0)) }</span>
                                    </td>
                                    <td colSpan={4}>
                                      <span style={{ fontWeight: 'bold' }}>Porcentaje de bono acreditado:&nbsp;&nbsp;</span>
                                      <span style={{ fontWeight: 'bold' }}>{ porcentajeBono.toFixed(0) } %</span>
                                    </td>
                                  </tr>
                                )
                              }
                            </tfoot>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )
            }
          </>
        }
      </Container>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    isCalculating: getIsCalculating(state)
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      isCalculatingAction,
      isGeneratingDocumentAction,
      isAboutToSendEmailAction,
      isAllowedToSendEmailAction
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard2);
