import axios from 'axios';

const getSimulationData = async (formData) => {
  const {
    age,
    incremento_con_inflacion,
    isr,
    periodicidad,
    plan,
    savingsQty,
    savingsTime
  } = formData;
  // const url = `https://script.google.com/macros/s/AKfycby05496MHKSUW5ZKeRF3sWxxSAc5hY1OMN08eLcIzke2jog3AxK3rse8NjXKZQeMNN9/exec?name=${name}&age=${age}&savingsQty=${savingsQty}&savingsTime=${savingsTime}`;
  // const url = 'https://dvyjnyskdltu6hdcaob6uecy7q0hebsz.lambda-url.us-east-1.on.aws/';
  // const url = `https://ay808fj849.execute-api.us-east-1.amazonaws.com/default/tabulador_api/?aportacion_pesos=${savingsQty}&edad=${Number(age)}&incremento_con_inflacion=${!!(incremento_con_inflacion - 1)}&isr=${isr}&periodicidad=${periodicidad}&plan=${plan}&plazo=${savingsTime}`;
  // const url = 'http://localhost:3000';
  const url = 'https://uxij97gl4k.execute-api.us-east-1.amazonaws.com/default/computetabuladordata-dev';
  const data = {
    aportacion_pesos: savingsQty,
    edad: Number(age),
    incremento_con_inflacion: !!(incremento_con_inflacion - 1),
    isr,
    periodicidad,
    plan,
    plazo: savingsTime
  };

const dataReceived = await axios.post(url, JSON.stringify(data) )
console.log({dataReceived})

return dataReceived;
};

const buildTableRegisters = (data) => {
  const registers = [];
  for (let i = 0; i < data.años_list.length; i++) {
    let register = {}
    register.años_list = data.años_list[i];
    register.edad_list = data.edad_list[i];
    register.aportacion_anual_list = data.aportacion_anual_list[i];
    register.aportacion_anual_acumulada_list = data.aportacion_anual_acumulada_list[i];
    register.saldo_del_fondo = data.saldo_del_fondo[i];
    register.saldo_disponible = data.saldo_disponible[i];
    register.saldo_disponible_neto_de_retención_de_impuestos_de_allianz = data.saldo_disponible_neto_de_retención_de_impuestos_de_allianz[i];
    register.beneficio_deducibilidad_list = data.beneficio_deducibilidad_list[i];
    registers.push(register);
  }
  return registers;
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const sendDocumentService = async (base64Image, name, fileName, email = 'contacto@networth.mx') => {
  const url = 'https://nu1b30avl4.execute-api.us-east-1.amazonaws.com/default/sendPdfEmail-dev';
  var myHeaders = new Headers();
  myHeaders.append("Content-Transfer-Encoding", "base64");
  myHeaders.append('Access-Control-Allow-Origin', 'http://localhost:3000');
  myHeaders.append('Access-Control-Allow-Credentials', 'true');

  var raw = JSON.stringify({
    "base64": base64Image,
    "name": name,
    "fileName": fileName,
    "email": email
  });

  var requestOptions = {
    method: 'POST',
    mode: 'no-cors',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(url, requestOptions)
    .then(response => {
      return response.text()
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('error', error)
      return error
    }
    );
};

const currencyFormat = data => {
  return `$ ${data}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
};

export {
  getSimulationData,
  buildTableRegisters,
  blobToBase64,
  sendDocumentService,
  currencyFormat
};
