import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown, Button, Card } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getisAllowedToSendEmail, getisAboutToSendEmail, getEmailData, isAboutToSendEmailAction } from '../../../../store/mode';

//style extra
import './headerstyle.scss';

//img
import logo from '../../../../assets/images/white-logo-2.png';

//collpase
import CollapseMenu from '../../../CollapseMenu';

const HeaderStyle2 = (props) => {
  //minisidebar
  const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 0) {
        if (document.getElementsByClassName('header-style-1 mm-top-navbar').length) {
          document.getElementsByClassName('header-style-1 mm-top-navbar')[0].classList.add('fixed')
        }
      }
      else {
        if (document.getElementsByClassName('header-style-1 mm-top-navbar').length) {
          document.getElementsByClassName('header-style-1 mm-top-navbar')[0].classList.remove('fixed')
        }
      }
    })
  })
  return (
    <>
      <div className="header-style-1 mm-top-navbar">
        <div className="container-fluid container-md mm-navbar-custom">
          <Navbar expand="lg" className="c-navbar px-0">
            <div className="mm-navbar-logo d-flex align-items-center justify-content-between">
              <Link to="/" className="header-logo">
                <img src={logo} className="img-fluid" alt="logo" />

              </Link>
            </div>
            <div className="d-flex align-items-center ml-auto">
              <div className="menu-horizontal">
                <nav className="mm-sidebar-menu">
                  <div className="mm-sidebar-logo d-flex align-items-center justify-content-between">
                    <Link to="/" className="header-logo">
                    </Link>
                    <div className="side-menu-bt-sidebar">
                      <i className="las la-bars wrapper-menu" onClick={minisidbar}></i>
                    </div>
                  </div>
                  <CollapseMenu />
                  <ul id="mm-sidebar-toggle" className="side-menu d-flex hover">
                    <li className="active">
                      <Link to="#Dashboards" className="collapsed" data-toggle="collapse" aria-expanded="false">
                        <svg className="svg-icon" id="mm-dash" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 14 15 14 15 22"></polyline>
                        </svg>
                        <span className="ml-2">Inicio</span>
                      </Link>
                    </li>
                    <li className=" ">
                    </li>
                    <li className=" ">
                    </li>
                  </ul>
                </nav>
              </div>
              {
                props.isAllowedToSendEmail &&
                <div className="d-flex align-items-center">
                  <Navbar.Toggle aria-controls="navbarSupportedContent">
                    <i className="ri-menu-3-line th-line"></i>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="navbarSupportedContent">
                    <Nav as="ul" className="navbar-list align-items-center">
                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle as={Button} href="#" variant=" search-toggle" id="dropdownMenuButton2" data-toggle="dropdown" aria-expanded="false">
                          <svg className="svg-icon text-white" id="mm-bell-1" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                        </Dropdown.Toggle>
                        {
                          props.isAboutToSendEmail &&
                            <Dropdown.Menu className="mm-sub-dropdown" aria-labelledby="dropdownMenuButton2">
                              <Card className="shadow-none m-0 border-0">
                                <Card.Body className="p-0 ">
                                  <div className="cust-title p-3">
                                    <h5 className="mb-0">Enviar reporte por correo</h5>
                                  </div>
                                  <div className="p-3">
                                    <Link to="#" className="mm-sub-card">
                                      <div className="media align-items-center">
                                        <div className="media-body ml-3">
                                          <input className="form-control" type="email" placeholder="juan.perez@correo.com" />
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <Link to="#" className="right-ic btn btn-primary btn-block position-relative p-2" role="button">
                                    <div className="dd-icon"><i className="las la-arrow-right mr-0"></i></div>
                                    Enviar
                                  </Link>
                                </Card.Body>
                              </Card>
                            </Dropdown.Menu>
                        }
                      </Dropdown>
                    </Nav>
                  </Navbar.Collapse>
                </div>
              }
            </div>
          </Navbar>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    emailData: getEmailData(state),
    isAllowedToSendEmail: getisAllowedToSendEmail(state),
    isAboutToSendEmail: getisAboutToSendEmail(state)
  };
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      isAboutToSendEmailAction
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyle2);
