import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

//store

import { Provider } from 'react-redux';
//reducer
import Store from './store'

ReactDOM.render(
  <React.Fragment>
     <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={Store}>
            <App />
        </Provider>
      </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);
