const initialState = {
  isCalculating: false,
  isGeneratingDocument: false,
  isAboutToSendEmail: false,
  isAllowedToSendEmail: false,
  emailData: null
};

// Action/Dispatch

export const isCalculatingAction = (value) => {
  return {type: 'ISCALCULATING', value}
};

export const isGeneratingDocumentAction = (value) => {
  return {type: 'ISGENERATINGDOCUMENT', value}
};

export const isAboutToSendEmailAction = (value) => {
  return {type: 'ISABOUTTOSENDEMAIL', value}
};

export const isAllowedToSendEmailAction = (value) => {
  return {type: 'ISALLOWEDTOSENDEMAIL', value}
};

export const setEmailDataAction = (value) => {
  return {type: 'SETEMAILDATA', value}
};


// Mutations/Reducer
const Mode = (state = initialState, action) => {
  switch (action.type) {
    case 'ISCALCULATING':
      return Object.assign({}, state, {
        isCalculating: action.value
      })
    case 'ISGENERATINGDOCUMENT':
      return Object.assign({}, state, {
        isGeneratingDocument: action.value
      })
    case 'ISABOUTTOSENDEMAIL':
      return Object.assign({}, state, {
        isAboutToSendEmail: action.value
      })
    case 'ISALLOWEDTOSENDEMAIL':
      return Object.assign({}, state, {
        isAllowedToSendEmail: action.value
      })
    case 'SETEMAILDATA':
      return Object.assign({}, state, {
        emailData: action.value
      })
    default:
      return state
  }
}

// Selectores
export const getIsCalculating = (state) => state.mode.isCalculating;
export const getisGeneratingDocument = (state) => state.mode.isGeneratingDocument;
export const getisAboutToSendEmail = (state) => state.mode.isAboutToSendEmail;
export const getisAllowedToSendEmail = (state) => state.mode.isAllowedToSendEmail;
export const getEmailData = (state) => state.mode.emailData;

export default Mode;
