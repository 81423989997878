import { Switch, Route } from 'react-router-dom'
import Layout2 from '../layouts/backend/Layout2';

const LayoutsRoute = () => {
    return (
      <Switch>
        <Route path="/" component={Layout2} />
      </Switch>
    )
}

export default LayoutsRoute;
