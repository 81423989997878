import React, { useEffect } from 'react';
import HeaderStyle2 from '../../components/partials/backend/HeaderStyle/HeaderStyle2';

// Router Component
import Layout2Route from '../../router/layout2-route';

//Loader
import Loader from '../../components/Loader';
import LoaderSimulator from '../../components/LoaderSimulator';
import LoaderDownload from '../../components/LoaderDownload';

const Layout2 = () => {
  useEffect(
    () => {
      document.body.classList.add("hospi-wrapper")
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return () => {
        document.body.classList.remove("hospi-wrapper")
      }
    }
  )
  return (
    <>
      <Loader />
      <LoaderSimulator />
      <LoaderDownload />
      <div className="wrapper bg-h-full l-horizontal overflow-hidden">
        <HeaderStyle2 />
        <div className="content-page  hospi-content">
          <Layout2Route />
        </div>
      </div>
    </>
  );
};

export default Layout2;
