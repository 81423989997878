import React from 'react';
import { Button, Modal } from 'react-bootstrap'
import { currencyFormat } from '../../helpers';

const OtherOptionModal = ({
  modalCase,
  show,
  onCancel,
  setFormData,
  setSavingsTimeLabel,
  setSavingsQtyLabel
}) => {

  const handleValueChange = (e) => {
    let { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    if (modalCase === 'savingsTime') {
      if (value > 25) {
        value = 25
      }
      setSavingsTimeLabel(`${value} años`)
    } else {
      setSavingsQtyLabel(currencyFormat(value));
    }
  };

  return (
    <Modal show={show} onHide={onCancel} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{
          modalCase === 'savingsTime' ?
            '¿Cuánto tiempo deseas ahorrar?' :
            '¿Qué cantidad deseas ahorrar?'
        }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalCase === 'savingsTime' ?
            <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'baseline' }}>
              <input className="form-control" type="number" placeholder="25" min={5} max={15} maxLength={2} name='savingsTime' onChange={handleValueChange} />
              <p> años</p>
            </div> :
            <input className="form-control" type="number" placeholder="2500.18" name='savingsQty' min={1500} onChange={handleValueChange} />
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OtherOptionModal;
