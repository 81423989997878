//router
import LayoutsRoute from './router/layouts-route';

import { useEffect } from 'react';
//vivus
import Vivus from 'vivus';
//Jquery
import jQuery from 'jquery';

//scss files
import './assets/scss/backend.scss';
import './assets/css/custom.css';

function App() {
  useEffect(
    () => {
      //vivusjs
      jQuery('.svg-icon').each(function () {
        const iconID = jQuery(this).attr('id');
        if (iconID !== undefined) {
          const iconVar = iconID.replace('-', '');
          window['mm' + iconVar] = new Vivus(iconID);
        }
      });
      jQuery(document).on("mouseenter", ".svg-icon", function () {
        let iconID = jQuery(this).attr('id');

        if (jQuery(this).find('svg').length > 0) {
          iconID = jQuery(this).find('svg').attr('id');
        }
        if (!iconID) return false;
        var iconVar = iconID.replace('-', '');
        if (window['mm' + iconVar] !== undefined) {
          window['mm' + iconVar].reset().play();
        } else {
          window['mm' + iconVar] = new Vivus(iconID);
          window['mm' + iconVar].reset().play();
        }
      });
    }
  )
  return (
    <div className="App">
      <LayoutsRoute />
    </div>
  );
}

export default App;
